import React from 'react';
import Svg from './svg';

function GithubIcon(props) {
  return (
    <Svg {...props}>
      <path d="M 4.0625 4 C 4.0625 4 3.53125 4.925781 3.53125 6.5 C 3.53125 7.546875 3.765625 8.125 3.9375 8.40625 C 2.734375 9.660156 2 11.347656 2 13.53125 C 2 19.125 5.527344 21 12 21 C 18.472656 21 22 19.125 22 13.53125 C 22 11.269531 21.304688 9.574219 20.15625 8.34375 C 20.441406 7.488281 20.769531 5.871094 19.96875 4 C 17.582031 4 15.570313 5.839844 15.5 5.90625 C 14.386719 5.660156 13.210938 5.53125 12 5.53125 C 10.761719 5.53125 9.542969 5.6875 8.40625 5.96875 C 6.808594 4.378906 4.886719 4 4.0625 4 Z M 15.21875 12 C 15.820313 11.992188 16.386719 12.054688 16.875 12.21875 C 18.179688 12.660156 19 13.945313 19 15.375 C 18.996094 19.339844 16.996094 20 11.875 20 C 8.066406 20 4.96875 19.285156 4.96875 15.5 C 4.96875 14.054688 5.71875 13.226563 6.25 12.71875 C 7.417969 11.601563 9.257813 12.15625 11.875 12.15625 C 13.0625 12.15625 14.214844 12.011719 15.21875 12 Z M 8 14 C 7.449219 14 7 14.671875 7 15.5 C 7 16.328125 7.449219 17 8 17 C 8.550781 17 9 16.328125 9 15.5 C 9 14.671875 8.550781 14 8 14 Z M 16 14 C 15.449219 14 15 14.671875 15 15.5 C 15 16.328125 15.449219 17 16 17 C 16.550781 17 17 16.328125 17 15.5 C 17 14.671875 16.550781 14 16 14 Z " />
    </Svg>
  );
}

export default GithubIcon;
