import React from 'react';
import Svg from './svg';

function EmailIcon(props) {
  return (
    <Svg {...props}>
     <path d="M 4 4 C 2.897 4 2 4.897 2 6 L 2 18 C 2 19.103 2.897 20 4 20 L 20 20 C 21.103 20 22 19.103 22 18 L 22 6 C 22 4.897 21.103 4 20 4 L 4 4 z M 4 6 L 20 6 L 20 6.0058594 L 12 11 L 4 6.0039062 L 4 6 z M 4 8.0039062 L 12 13 L 20 8.0058594 L 20.001953 18 L 4 18 L 4 8.0039062 z"/>
    </Svg>
  );
}

export default EmailIcon;
